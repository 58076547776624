@import "../mixins/mixins";

.login-page {
  @include display-flex(column);
  position: absolute;
  width: 500px;
  //height: 500px;
  top: 50%;
  left: 50%;
  background-color: $input-gray;

  &-username {
    margin-top: 25px;
  }
}
