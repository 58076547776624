@import "../mixins/mixins";

.main-page {
  @include display-flex(column);

  &-login {
    @include display-flex(row);
    justify-content: flex-end;
  }

  &-map-box {
    @include display-flex(row);
  }

  &-operations {
    width: 500px;
  }
}
