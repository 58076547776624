@import "../mixins/mixins";

.incident-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  //filter: blur(8px);
  //-webkit-filter: blur(8px);
}

.incident-modal-buttons {
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  //  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  //  rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: absolute;
  left: 14px;
  bottom: 90px;
  border-radius: 30px;
}
