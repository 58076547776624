@mixin flex-text-center() {
  display: inline-block;
  align-self: center;
}

@mixin display-flex($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin centerInParent() {
  display: flex;
  align-items: center;
  justify-content: center;
}
