@import "../mixins/mixins";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    width: 60%;
    background-color: #f7f4f1;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 10px;

    &-header {
      @include display-flex(row);
      justify-content: space-between;
      padding: 15px;
      border-bottom: 2px solid $colour-black;

      &-title {
        margin: 0;
        line-height: 1.57143;
        font-size: 17px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-style: normal;
      }
    }

    &-body {
      position: relative;
      padding: 24px;
      font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 12px;
    }

    &-footer {
      margin-right: 25px;
      margin-left: auto;
      margin-bottom: 25px;
    }

    &-field {
      margin-top: 15px;
      margin-bottom: 15px;
      width: auto;
      @include display-flex(column);
    }

    &-section {
      @include display-flex(column);
      align-items: center;
      //border-bottom: 1px solid #f7f7f7;
      margin-bottom: 10px;

      &-button {
        //@include display-flex(row);
        margin-right: 10px;
      }
    }
  }
}
