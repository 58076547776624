@import "../mixins/mixins";

.inc-card {
  margin-top: 75px;
  position: relative;
  width: 200px;
  height: auto;
  margin-left: 30px;
  border-radius: 5px;
  background-color: #f8f9fa;
  border-style: solid;
  border-width: 2px;

  &-form-group {
    margin-left: 25px;
    margin-bottom: 5px;
  }
}

.incident-box {
  display: flex;
  flex-direction: row;

  span {
    //margin-left: 2px;
    margin-top: 20px;
    font: $main-font;
    color: #d1d1d1;
  }
}
