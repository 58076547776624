.but-action-style {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: white;
}

.but-action-create {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 20px;
  margin-bottom: 20px;
  //color: #adf7b6;
  //color: #555555;
  background-color: #bee5d3;
  color: #3b5360;
  border-style: solid;
  border-width: 4px;
  border-color: #fdf6f0;
  border-radius: 30px;
}

.but-action-login {
  position: absolute;
  top: 10px;
  right: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #f9f9f9;
  border-radius: 30px;
  color: #aaaaaa;
}

.but-action-filter {
  position: absolute;
  top: 10px;
  left: 10px;
  margin-left: 20px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #f9f9f9;
  //background-color: #BEE5D3;;
  //color: #3B5360;
  border-radius: 30px;
  border-width: 3px;
  border-style: solid;
  padding: 5px;
  color: #aaaaaa;
  //color: #aaaaaa;
}

.but-gps {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: absolute;
  bottom: 125px;
  right: 15px;
  border-radius: 30px;
  background-color: #bee5d3;
  color: #3b5360;
  border-width: 2px;
  border-style: solid;
  padding: 5px;
}
