$colour-dark-grey: #aaaaaa;
$colour-yellow: #ffff66;
$colour-yellow-1: #ffc252;
$colour-orange: #f2994a;
$colour-aqua: #00ffff;
$colour-purple: #b452ff;
$colour-blue: #0052cc;
$colour-black: #000000;
$colour-white: #ffffff;
$colour-pastel-grey: #a8c1c6;
$input-blue: #357eb7;
$input-gray: #b4b4b4;

$validation-error: #c84714;
$validation-warning: #f1af52;

$link-color: #2a638f;

$list-item-even: #f8f9fa;
