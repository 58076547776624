@import "../mixins/mixins";

.info-box {
  @include display-flex(column);
  margin-left: 5px;
  &-label {
    //padding-top: 5px;
    @include fieldLabel;
  }

  &-value {
    padding-bottom: 5px;
    @include fieldValue;
  }
}
