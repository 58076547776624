@import "styles/pages/appPage";
@import "styles/pages/loginPage";

.map-buttons {
  @include display-flex(row);
  justify-content: space-between;
  margin-top: 25px;
  margin-right: 25px;

  &-create {
    @include display-flex(column);
    justify-content: flex-end;
    //margin-left: auto;
    margin-right: 10px;
    //margin-top: auto;
    //margin-bottom: 50px;

    &-position {
      justify-content: flex-end;
      display: flex;
    }
  }
}
