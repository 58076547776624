@mixin fieldLabel {
  font-family: $label-font;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $colour-dark-grey;
}

@mixin fieldValue {
  font-family: $label-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: $colour-black;
}
