@import "../mixins/mixins";

.inc-create {
  @include display-flex(column);
  //min-height: 200px;
  //min-width: 200px;

  &-info {
    @include display-flex(column);
    border-bottom-style: dashed;
    border-bottom-color: $colour-dark-grey;

    &-label {
      @include fieldValue;
    }

    &-select-box {
      min-width: 200px;
      margin-bottom: 10px;

      select {
        height: 30px;
        width: 200px;
        position: relative;
        border-radius: 5px;
        background-color: $colour-dark-grey;
      }
    }
  }

  &-button {
    margin-top: 10px;
    width: 200px;
  }
}
