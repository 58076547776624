@import "../mixins/mixins";

.result-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    min-width: 300px;
    background-color: $colour-white;
    border-style: solid;
    border-color: black;
    border-radius: 10px;
  }
}
