@charset "UTF-8";
@import "./variables/variables";
@import "./mixins/mixins";
@import "./pages/pages";
@import "./components/components";

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

label,
h3 {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: large;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 0.25rem;
  box-sizing: border-box;
  font-size: larger;
}
//
//
//html {
//  height: 100%;
//}
//
//body {
//  background-color: #e9f0f3;
//  height: calc(100% - 45px);
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//#root {
//  height: 100%;
//  //margin-top: 45px;
//}
//
//.App_V1 {
//  @include display-flex(column);
//  //width: 100%;
//  //margin: auto;
//  //align-items: center;
//}
