@import "../mixins/mixins";

.inc-detail {
  @include display-flex(column);
  min-height: 200px;
  min-width: 200px;

  &-info {
    @include display-flex(column);
    border-bottom-style: dashed;
    border-bottom-color: #aaaaaa;
  }

  &-button {
    margin-top: 10px;
    width: 200px;
  }
}
